import { FunctionComponent } from "react";
import Footer from "./Footer";

const Layout: FunctionComponent = ({ children }) => {
  return (
    <>
      <div className="w-full">{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
