import { ThemeTypes } from "../utils/prop-types";

export const defaultFont: GeistUIThemesFont = {
  sans: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  mono: "Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace",
};

export const defaultBreakpoints: GeistUIThemesBreakpoints = {
  xs: {
    min: "0",
    max: "640px",
  },
  sm: {
    min: "640px",
    max: "768px",
  },
  md: {
    min: "768px",
    max: "1024px",
  },
  lg: {
    min: "1024px",
    max: "1280px",
  },
  xl: {
    min: "1280px",
    max: "1536px",
  },
  "2xl": {
    min: "1536px",
    max: "10000px",
  },
};

export const defaultLayout: GeistUIThemesLayout = {
  gap: "16pt",
  gapNegative: "-16pt",
  gapHalf: "8pt",
  gapHalfNegative: "-8pt",
  gapQuarter: "4pt",
  gapQuarterNegative: "-4pt",
  pageMargin: "16pt",
  pageWidth: "750pt",
  pageWidthWithMargin: "782pt",
  breakpointMobile: defaultBreakpoints.xs.max,
  breakpointTablet: defaultBreakpoints.sm.max,
  radius: "5px",
};

export interface GeistUIThemesPalette {
  /* eslint-disable @typescript-eslint/camelcase */
  accents_1: string;
  accents_2: string;
  accents_3: string;
  accents_4: string;
  accents_5: string;
  accents_6: string;
  accents_7: string;
  accents_8: string;
  /* eslint-enable camelcase */
  background: string;
  foreground: string;
  selection: string;
  secondary: string;
  code: string;
  border: string;
  success: string;
  successLighter: string;
  successLight: string;
  successDark: string;
  error: string;
  errorLighter: string;
  errorLight: string;
  errorDark: string;
  warning: string;
  warningLighter: string;
  warningLight: string;
  warningDark: string;
  cyan: string;
  cyanLighter: string;
  cyanLight: string;
  cyanDark: string;
  violet: string;
  violetLighter: string;
  violetLight: string;
  violetDark: string;
  link: string;
  purple: string;
  magenta: string;
  alert: string;
}

export interface GeistUIThemesExpressiveness {
  linkStyle: string;
  linkHoverStyle: string;
  dropdownBoxShadow: string;
  scrollerStart: string;
  scrollerEnd: string;
  shadowSmall: string;
  shadowMedium: string;
  shadowLarge: string;
  portalOpacity: number;
}

export interface GeistUIThemesLayout {
  gap: string;
  gapNegative: string;
  gapHalf: string;
  gapHalfNegative: string;
  gapQuarter: string;
  gapQuarterNegative: string;
  pageMargin: string;
  pageWidth: string;
  pageWidthWithMargin: string;
  breakpointMobile: string;
  breakpointTablet: string;
  radius: string;
}

export interface GeistUIThemesFont {
  sans: string;
  mono: string;
}

export interface BreakpointsItem {
  min: string;
  max: string;
}

export interface GeistUIThemesBreakpoints {
  xs: BreakpointsItem;
  sm: BreakpointsItem;
  md: BreakpointsItem;
  lg: BreakpointsItem;
  xl: BreakpointsItem;
  "2xl": BreakpointsItem;
}

export interface GeistUIThemes {
  type: ThemeTypes;
  font: GeistUIThemesFont;
  layout: GeistUIThemesLayout;
  palette: GeistUIThemesPalette;
  breakpoints: GeistUIThemesBreakpoints;
  expressiveness: GeistUIThemesExpressiveness;
}

export const palette: GeistUIThemesPalette = {
  /* eslint-disable @typescript-eslint/camelcase */
  accents_1: "#fafafa",
  accents_2: "#eaeaea",
  accents_3: "#999",
  accents_4: "#888",
  accents_5: "#666",
  accents_6: "#444",
  accents_7: "#333",
  accents_8: "#111",
  /* eslint-enable camelcase */
  background: "#fff",
  foreground: "#000",
  selection: "#79ffe1",
  secondary: "#666",
  code: "#f81ce5",
  border: "#eaeaea",
  error: "#e00",
  errorLight: "#ff1a1a",
  errorLighter: "#f7d4d6",
  errorDark: "#c50000",
  success: "#008887",
  successLight: "#928ff5",
  successLighter: "#e2e2ff",
  successDark: "#0b0a33",
  warning: "#f59e0b",
  warningLight: "#f7b955",
  warningLighter: "#ffefcf",
  warningDark: "#ab570a",
  cyan: "#50e3c2",
  cyanLighter: "#aaffec",
  cyanLight: "#79ffe1",
  cyanDark: "#29bc9b",
  violet: "#7928ca",
  violetLighter: "#e3d7fc",
  violetLight: "#8a63d2",
  violetDark: "#4c2889",
  purple: "#f81ce5",
  alert: "#ff0080",
  magenta: "#eb367f",
  link: "#0070f3",
};

export const expressiveness: GeistUIThemesExpressiveness = {
  linkStyle: "none",
  linkHoverStyle: "none",
  dropdownBoxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.02)",
  scrollerStart: "rgba(255, 255, 255, 1)",
  scrollerEnd: "rgba(255, 255, 255, 0)",
  shadowSmall: "0 5px 10px rgba(0, 0, 0, 0.12)",
  shadowMedium: "0 8px 30px rgba(0, 0, 0, 0.12)",
  shadowLarge: "0 30px 60px rgba(0, 0, 0, 0.12)",
  portalOpacity: 0.25,
};

export const font = defaultFont;

export const breakpoints = defaultBreakpoints;

export const layout = defaultLayout;

export const theme: GeistUIThemes = {
  type: "light",
  font,
  layout,
  palette,
  breakpoints,
  expressiveness,
};
