import Link from "next/link";
import Button from "../Button";
import Image from "next/image";

type Props = {
  hideButtons?: boolean;
};

const HomepageNavbar: React.FC<Props> = ({ children, hideButtons }) => {
  return (
    <nav className="w-full py-4 px-2 md:px-4 flex-grow-0 fixed top-0 inset-x-0 navbar-blur z-modal">
      <div className="w-full max-w-7xl h-full mx-auto flex justify-between items-center">
        <>
          <div className="hidden sm:flex w-64">
            <Link passHref href={`/mission`}>
              <a className="">
                <Button homepageBtn auto type="abort">
                  Unsere Mission
                </Button>
              </a>
            </Link>
            <Link href="/pricing">
              <a href="/pricing">
                <Button homepageBtn auto type="abort">
                  Preise
                </Button>
              </a>
            </Link>
          </div>
          <div>
            <Link passHref href={`${process.env.NEXT_PUBLIC_SITE_URL}`}>
              <a
                className="flex items-center justify-center"
                style={{
                  filter: "drop-shadow(0 0.5px 0.5px #fff)",
                }}
              >
                <Image
                  src="/static/logo/xtribe-logo-128.png"
                  height={48}
                  width={164.625}
                  className="h-12"
                />
              </a>
            </Link>
          </div>
          <div className="items-center w-64 flex justify-end">
            <div className="hidden sm:inline-block">
              <a
                href={`${process.env.NEXT_PUBLIC_AUTH_URL}/signin`}
                rel="prefetch"
              >
                <Button auto homepageBtn className="sm:mr-2" type="abort">
                  Anmelden
                </Button>
              </a>
            </div>
            <div className="inline-block sm:hidden">
              <a
                href={`${process.env.NEXT_PUBLIC_AUTH_URL}/signin`}
                rel="prefetch"
              >
                <Button auto homepageBtn className="sm:mr-2">
                  Anmelden
                </Button>
              </a>
            </div>

            <div className="hidden sm:inline-block">
              <Link passHref href={`${process.env.NEXT_PUBLIC_SITE_URL}/start`}>
                <a>
                  <Button auto homepageBtn ghost type="success">
                    Kostenlos Starten
                  </Button>
                </a>
              </Link>
            </div>
          </div>
        </>
      </div>
    </nav>
  );
};

export default HomepageNavbar;
