import { motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useState } from "react";
import SliderItem from "./SliderItem";
import { useInView } from "react-intersection-observer";

interface Props {
  slides: {
    title: string;
    description?: string;
    image: string;
  }[];
}

const Slider = ({ slides }: Props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [touched, setTouched] = useState(false);
  const variants = {
    end: { width: "100%" },
    start: { width: "0%" },
  };
  const [ref, inView] = useInView();
  useEffect(() => {
    if (!touched && inView) {
      const timeoutKey = setTimeout(() => {
        setActiveSlide((current) => {
          if (current === slides.length - 1) {
            return 0;
          } else {
            return current + 1;
          }
        });
      }, 5000);
      return () => {
        clearTimeout(timeoutKey);
      };
    } else return () => {};
  }, [activeSlide, inView]);

  return (
    <div
      className="w-full  overflow-hidden flex flex-col-reverse lg:flex-row -mx-4 mt-8"
      ref={ref}
    >
      <div className="w-full lg:w-2/3 lg:px-4">
        <div className=" relative" style={{ paddingTop: "56.25%" }}>
          <div className="absolute h-full w-full left-0 top-0 bg-gray-50">
            <Image
              src={slides[activeSlide] ? slides[activeSlide]?.image : ""}
              width={890}
              height={500}
              objectFit="contain"
            />
          </div>
        </div>
      </div>
      <div className="overflow-x-auto whitespace-nowrap lg:overflow-x-auto lg:whitespace-normal flex  lg:flex-col px-4 lg:px-0 mb-2 lg:mb-0 lg:-my-4 lg:w-1/3">
        {slides.map((el, index) => {
          return (
            <div
              className="relative whitespace-normal lg:py-4 w-80 lg:w-auto flex-shrink-0 flex-grow-0 inline"
              key={el.title + " " + index}
            >
              <SliderItem
                onClick={() => {
                  setActiveSlide(index);
                  setTouched(true);
                }}
                title={el.title}
                description={el.description}
                active={activeSlide === index}
              />
              {activeSlide === index && inView && !touched && (
                <motion.div
                  transition={{ duration: 5, ease: "linear" }}
                  variants={variants}
                  animate="end"
                  initial="start"
                  className="absolute w-0 bg-black left-0 bottom-0 lg:bottom-4 h-0.5"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
