import Image from "next/image";
import Button from "@xtribe/shared/components/lib/Button";
import NextHead from "@xtribe/shared/components/lib/Head/NextHead";
import Layout from "@xtribe/shared/components/lib/Layout/Layout";
import HomepageNavbar from "@xtribe/shared/components/lib/Navbar/HomepageNavbar";
import { addColorAlpha, hexToRgb } from "@xtribe/shared/utils/color";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import Slider from "../components/Slider/Slider";
import Link from "next/link";

const Index = () => {
  const [colorState, setColorState] = useState({
    text: "text-black",
    textSoft: "text-gray-200",
    bg: "bg-green-background",
    bgSoft: "bg-green-light",
  });

  const [backgroundGreenDiv1, backgroundGreen1InView] = useInView({
    /* Optional options */
    threshold: 0.1,
  });
  const [darkGreenDiv1, darkGreen1InView] = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  const [whiteDiv1, whiteGreen1InView] = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  const [lightGreenDiv1, lightGreen1InView] = useInView({
    /* Optional options */
    threshold: 0.1,
  });
  const [backgroundGreenDiv2, backgroundGreen2InView] = useInView({
    /* Optional options */
    threshold: 0.1,
  });
  const [darkGreenDiv2, darkGreen2InView] = useInView({
    /* Optional options */
    threshold: 0.1,
  });
  const [greenDiv1, green1InView] = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  useEffect(() => {
    if (backgroundGreen1InView) {
      setColorState({
        text: "text-black",
        textSoft: "text-gray-600",
        bg: "bg-green-background",
        bgSoft: "bg-green-light",
      });
    } else if (darkGreen1InView) {
      setColorState({
        text: "text-white",
        bg: "bg-green-dark",
        textSoft: "text-gray-200",
        bgSoft: "bg-green-medium",
      });
    } else if (whiteGreen1InView) {
      setColorState({
        text: "text-black",
        bg: "bg-white",
        textSoft: "text-gray-600",
        bgSoft: "bg-gray-200",
      });
    } else if (lightGreen1InView) {
      setColorState({
        text: "text-black",
        bg: "bg-green-light",
        textSoft: "text-gray-600",
        bgSoft: "bg-green-pastel",
      });
    } else if (backgroundGreen2InView) {
      setColorState({
        text: "text-black",
        textSoft: "text-gray-600",
        bg: "bg-green-background",
        bgSoft: "bg-green-light",
      });
    } else if (darkGreen2InView) {
      setColorState({
        text: "text-white",
        bg: "bg-green-dark",
        textSoft: "text-gray-200",
        bgSoft: "bg-green-medium",
      });
    } else if (green1InView) {
      setColorState({
        text: "text-white",
        bg: "bg-green",
        textSoft: "text-gray-200",
        bgSoft: "bg-green-pastel",
      });
    }
  }, [
    backgroundGreen1InView,
    darkGreen1InView,
    green1InView,
    darkGreen2InView,
    backgroundGreen2InView,
    lightGreen1InView,
    whiteGreen1InView,
  ]);

  return (
    <div
      className={`theme-admin overflow-x-hidden overflow-y-hidden transition-colors duration-300 ease-out ${colorState.bg} ${colorState.text}`}
    >
      <NextHead
        title={"Turn your audience into your Tribe — xTribe"}
        description={
          "Vermarkte eigenen Content und biete Premium-Abonnements, die es in sich haben und erschaffe dein ortsunabhängiges Business, rund um deine kreative Arbeit."
        }
      />
      <HomepageNavbar />
      <Layout>
        <div
          ref={backgroundGreenDiv1}
          className={`mt-16 max-w-7xl mx-auto overflow-hidden px-4`}
        >
          <div
            className={`transition-opacity duration-700 ease-out ${
              backgroundGreen1InView
                ? "opacity-100 visible"
                : "opacity-0 invisible"
            }`}
          >
            <div className="text-center mb-6 pt-8 md:pt-12 md:mb-12 px-2 md:px-4">
              <h1 className="h1 normal-case font-bold mb-4  tracking-tighter">
                Turn your audience into your Tribe{" "}
              </h1>
              <p className="max-w-4xl mx-auto text-2xl mb-6">
                Vermarkte eigenen Content und biete Premium-Abonnements, die es
                in sich haben und erschaffe dein ortsunabhängiges Business, rund
                um deine kreative Arbeit.
              </p>
              <Link href="/start" passHref>
                <a>
                  <Button size="large" homepageBtn type="success">
                    Erschaffe deinen Tribe &rarr;
                  </Button>
                </a>
              </Link>
              <div className="mt-12">
                <Image
                  src="/static/homepage/online-studio-large.png"
                  width={660}
                  height={491}
                />
              </div>
            </div>
            <div className="mt-64">
              <div className="grid md:grid-cols-12 md:grid-rows-8 gap-6 md:gap-10">
                <div className="md:row-start-1 md:row-span-2 md:col-start-1 md:col-span-7">
                  <h6 className="h6">Für die Kreativen von morgen</h6>
                  <h2 className="mt-4 h3">
                    <span className="text-green-dark">
                      Erschaffe ein einzigartiges digitales Zuhause,
                    </span>{" "}
                    <span className="opacity-60">
                      damit du und deine Mitglieder gerne und oft zu Besuch
                      kommen.
                    </span>
                    <br />
                    An Vorgaben glauben wir nicht. Es gibt nur deinen Geschmack
                    und maximale Flexibilität.
                  </h2>
                </div>
                <div className="self-end md:row-start-1 md:row-span-3 md:col-start-8 md:col-span-5  w-full bg-red-pastel px-10 2xl:px-12 py-12 2xl:py-20">
                  <div className="flex justify-center">
                    <Image
                      src="/static/homepage/online-coaching.png"
                      width={352}
                      height={269}
                    />
                  </div>
                  <h2 className="mt-6 h3 text-red-dark">
                    Microschools, Teaching & Coaching
                  </h2>
                </div>
                <div className="self-start md:row-start-3 md:row-span-2 md:col-start-1 md:col-span-6  w-full bg-secondary px-10 2xl:px-12 py-12 2xl:py-20">
                  <div className="flex justify-center">
                    <Image
                      src="/static/homepage/online-studio.png"
                      width={361}
                      height={268}
                    />
                  </div>
                  <h2 className="mt-6 h3 text-red-dark">
                    Yoga, Pilates, Fitness & Gesundheit
                  </h2>
                </div>
                <div className="self-start md:row-start-4 md:row-span-2 md:col-start-7 md:col-span-6  w-full bg-yellow px-10 2xl:px-12 py-12 2xl:py-20">
                  <div className="flex justify-center">
                    <Image
                      src="/static/homepage/online-tribe.png"
                      width={327.85}
                      height={268}
                    />
                  </div>
                  <h2 className="mt-6 h3 text-red-dark">
                    Tribes & Mitgliederbereiche
                  </h2>
                </div>
                <div className="md:row-start-5 md:row-span-1 md:col-start-1 md:col-span-6 py-20">
                  <div className="w-full mx-auto flex justify-center">
                    <Link href="/start" passHref>
                      <a>
                        <Button size="large" homepageBtn type="success">
                          Erschaffe deinen Tribe &rarr;
                        </Button>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={darkGreenDiv1} className={`pt-64 px-4 overflow-hidden`}>
          <div
            className={`transition-opacity  duration-700 ease-out ${
              darkGreen1InView && !backgroundGreen1InView
                ? "opacity-100 visible"
                : "opacity-0 invisible"
            }`}
          >
            <h1 className=" text-center max-w-4xl mx-auto text-6xl sm:text-7xl tracking-tighter leading-none normal-case font-bold mb-4">
              So wie du bist, bist du hier genau richtig!
            </h1>
            <div className="max-w-7xl mx-auto mt-96 relative ">
              <h2 className="max-w-5xl h3 mb-4">
                Mit X Tribe wollen wir für dich und andere Kreative einen{" "}
                <span className="text-green">digitalen & physischen Ort</span>{" "}
                schaffen, bei dem du zum Leader deines Tribes wirst.
              </h2>
              <div className="text-center font-bold tracking-tighter leading-tight text-7xl mt-32 ">
                <div className="text-red-pastel">Verantwortung</div>
                <div className="text-beige-pastel">Vielseitigkeit </div>
                <div className="text-yellow-pastel">Vertrauen</div>
                <div className="mt-4">
                  <Image
                    src="/static/logo/xtribe-logo.png"
                    height={80}
                    width={274.375}
                    objectFit="contain"
                    className=""
                  />
                </div>
              </div>
            </div>
            <div className="max-w-7xl mx-auto mt-64 md:mt-96">
              <div className="max-w-5xl ml-auto">
                <div>
                  <h6 className="h6">Lass deinen Tribe wachsen</h6>
                  <h2 className="mt-4 h3">
                    <span className="text-green">
                      Mache aus Followern zahlende Fans,
                    </span>{" "}
                    die zu deinem Tribe gehören.
                  </h2>
                  <ul className="list-disc list-inside mt-8">
                    <li className="h4 mt-2">
                      Erstelle dir deine eigene xTribe custom domain
                    </li>
                    <li className="h4 mt-2">
                      Vermarkte deinen aufgezeichneten Content auf xTribe
                    </li>
                    <li className="h4 mt-2">
                      Erschaffe verschiedene Spaces, so wie sie für deinen Tribe
                      passen
                    </li>{" "}
                    <li className="h4 mt-2">
                      Veranstalte digitale Live Events
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="relative h-64 md:h-80 mt-16">
              <div
                className="absolute w-64 xl:w-80 2xl:w-96"
                style={{ top: "3%", left: "8%" }}
              >
                <Image
                  height={112}
                  width={358}
                  src="/static/homepage/homepage-order-1.jpg"
                />
              </div>
              <div
                className="absolute w-64 xl:w-80 2xl:w-96"
                style={{ bottom: "4%", left: "26%" }}
              >
                <Image
                  height={112}
                  width={358}
                  src="/static/homepage/homepage-order-2.jpg"
                />
              </div>
              <div
                className="absolute  hidden lg:block w-64 xl:w-80 2xl:w-96"
                style={{ top: "8%", left: "43%" }}
              >
                <Image
                  height={112}
                  width={358}
                  src="/static/homepage/homepage-order-3.jpg"
                />
              </div>
              <div
                className="absolute hidden lg:block w-64 xl:w-80 2xl:w-96"
                style={{ bottom: "12%", right: "16%" }}
              >
                <Image
                  height={112}
                  width={358}
                  src="/static/homepage/homepage-order-4.jpg"
                />
              </div>
              <div
                className="absolute hidden sm:block w-64 xl:w-80 2xl:w-96"
                style={{ top: "3%", right: "4%" }}
              >
                <Image
                  height={112}
                  width={358}
                  src="/static/homepage/homepage-order-5.jpg"
                />
              </div>
            </div>
          </div>
        </div>
        <div ref={whiteDiv1} className=" px-4">
          <div
            className={`transition-opacity duration-700 ease-out ${
              !darkGreen1InView && whiteGreen1InView
                ? "opacity-100 visible"
                : "opacity-0 invisible"
            }`}
          >
            <div className="max-w-7xl mx-auto">
              <div className="max-w-5xl">
                <h6 className="h6">Für die Kreativen von morgen </h6>
                <h2 className="mt-4 h3">
                  <span className="text-green">Bleib in Verbindung.</span>{" "}
                  <span className="opacity-60">
                    Interagiere mit deinen Mitglieder so wie du es möchtest –
                  </span>{" "}
                  wir machen es dir spielend einfach.
                </h2>
              </div>
              <Slider
                slides={[
                  {
                    title: "Private und öffentliche Spaces",
                    description:
                      "Organisiere deinen Tribe und deinen Content in Spaces. Du kannst auch private Spaces für exklusive Mitgliedschaften erstellen.",
                    image: "/static/homepage/homepage-spaces.jpg",
                  },
                  {
                    title: "Live Events",
                    description:
                      "Verbinde deinen Zoom-Account, stell dich vor die Kamera und interagiere mit deinem Tribe.",
                    image: "/static/homepage/homepage-spaces.jpg",
                  },
                  {
                    title: "On-Demand Videos",
                    description:
                      "Lade ganz einfach deine schon abgedrehten Videos hoch und vermarkte dein Wissen. Online & immer verfügbar.",
                    image: "/static/homepage/homepage-spaces.jpg",
                  },
                  {
                    title: "Community Posts",
                    description:
                      "Ein Tribe lebt von der Interaktion. Auch wenn du mal gerade nicht dabei bist, kann sich dein Tribe untereinander austauschen.",
                    image: "/static/homepage/homepage-spaces.jpg",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div ref={lightGreenDiv1} className="pt-64 px-4">
          <div
            className={`transition-opacity duration-700 ease-out ${
              !whiteGreen1InView && lightGreen1InView
                ? "opacity-100 visible"
                : "opacity-0 invisible"
            }`}
          >
            <div className="md:flex md:items-center max-w-7xl mx-auto">
              <div className="md:w-1/2">
                <Image
                  src="/static/homepage/stripe-ecommerce.jpg"
                  width={598}
                  height={615}
                />
              </div>
              <div className="h-12 md:w-12"></div>
              <div className="md:w-1/2">
                <h6 className="h6 text-red">E-Commerce</h6>
                <h2 className="mt-4 h3">
                  <span className="text-green-medium">
                    Verdiene Geld mit xTribe,{" "}
                  </span>{" "}
                  <span className="opacity-60">
                    indem du deinen Content, deine Persönlichkeit und dein
                    Wissen in aufgezeichneten Kursen, Live Sessions, oder
                    kompletten Produkten anbietest.
                  </span>
                  <br />
                </h2>
                <h4 className="h4 tracking-tight mt-4 opacity-50">
                  Bei uns gibt es keine versteckten Kosten oder
                  Zahlungsgebühren. Verbinde dein Stripe-Konto mit xTribe und
                  biete dein Können und Wissen deinem Zielpublikum an.
                </h4>
              </div>
            </div>
            <div className="mt-64 max-w-7xl mx-auto">
              <div className="">
                <h6 className="h6 text-red">Branding</h6>
                <h2 className="mt-4 h3">
                  <span className="text-green-medium">Dein Tribe.</span> Deine
                  Plattform.{" "}
                  <span className="text-green-medium">Dein Look & Feel.</span>{" "}
                  <span className="opacity-60">
                    Passe deinen Space and dich und deine Marke an, damit sich
                    deine Mitglieder willkommen fühlen.
                  </span>{" "}
                </h2>
              </div>
              <div className="relative md:py-72 lg:py-80 mt-16">
                <div
                  className="md:absolute w-full md:w-80 xl:w-auto"
                  style={{ top: "16%", left: "4%" }}
                >
                  <Image
                    height={268}
                    width={427}
                    src="/static/homepage/tribe-colors.jpg"
                  />
                </div>
                <div
                  className="md:absolute w-full ml-12 mt-4 sm:ml-24 sm:mt-8 md:ml-0 md:mt-0 md:w-80 xl:w-auto"
                  style={{ bottom: "20%", left: "56.25%" }}
                >
                  <Image
                    height={180}
                    width={425}
                    src="/static/homepage/tribe-subdomain.jpg"
                  />
                </div>
                <div
                  className="md:absolute w-full -ml-6 mt-3 sm:ml-64 sm:mt-6 md:ml-0 md:mt-0 md:w-80 xl:w-auto"
                  style={{ top: "4%", right: "8%" }}
                >
                  <Image
                    height={176}
                    width={393}
                    src="/static/homepage/tribe-logo.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={backgroundGreenDiv2} className="pt-96 px-4">
          <div
            className={`transition-opacity duration-700 ease-out ${
              !lightGreen1InView && backgroundGreen2InView
                ? "opacity-100 visible"
                : "opacity-0 invisible"
            }`}
          >
            <div className="max-w-7xl mx-auto text-center">
              <h1 className="text-6xl sm:text-7xl tracking-tighter leading-none normal-case font-bold mb-12">
                Haben wir dich überzeugt?
              </h1>
              <Link href="/start" passHref>
                <a>
                  <Button size="large" homepageBtn type="success">
                    Erschaffe deinen Tribe &rarr;
                  </Button>
                </a>
              </Link>
              <h1 className="h1 mt-96">Falls nicht...</h1>
            </div>
          </div>
        </div>
        <div ref={darkGreenDiv2} className="pt-96 px-4">
          <div
            className={`transition-opacity duration-700 ease-out ${
              !backgroundGreen2InView && darkGreen2InView
                ? "opacity-100 visible"
                : "opacity-0 invisible"
            }`}
          >
            <div className="text-center max-w-7xl mx-auto">
              <h1 className="text-6xl sm:text-7xl tracking-tighter leading-none normal-case font-bold mb-4">
                Wir sind lange noch nicht fertig ...
              </h1>
            </div>
            <div className="max-w-7xl mx-auto mt-96 lg:flex lg:flex-wrap ">
              <div className="py-3 lg:p-6 lg:w-2/5">
                <div className="h-full bg-green-pastel px-10 2xl:px-12 py-12 2xl:py-20">
                  <h6 className="h6 text-yellow-pastel">Coming Soon</h6>
                  <h2 className="mt-4 h3 text-green-dark">Zoom Integration</h2>
                  <p className="mt-4 text-xl text-green-dark">
                    Integriere ganz einfach Zoom in deinen Tribe Space und geh
                    direkt live. Schnell, einfach und bequem aus xTribe heraus.
                  </p>
                </div>
              </div>
              <div className="py-3 lg:p-6 lg:w-3/5">
                <div className="h-full bg-secondary px-10 2xl:px-12 py-12 2xl:py-20">
                  <h6 className="h6 text-purple">Neu</h6>
                  <h2 className="mt-4 h3 text-beige-dark">Concierge Team</h2>
                  <p className="mt-4 text-xl text-beige-dark">
                    Wir helfen dir ganz einfach von einer anderen Plattform zu
                    uns zu wechseln. Dabei stehen wir dir live und persönlich zu
                    Seite ganz ohne Chatboot oder Telefonwarteschlangen –
                    <span className="font-bold"> und das alles for free.</span>
                  </p>
                </div>
              </div>

              <div className="py-3 lg:p-6 lg:w-1/2 ">
                <div className="h-full bg-red-pastel px-10 2xl:px-12 py-12 2xl:py-20 relative overflow-hidden">
                  <div
                    className="absolute -right-32 -top-32 font-bold leading-none text-secondary "
                    style={{ fontSize: 700 }}
                  >
                    X
                  </div>
                  <div className="relative z-30">
                    <h6 className="h6 text-yellow-pastel">
                      Für alle Mitglieder
                    </h6>
                    <h2 className="mt-4 h3 text-red-dark">xTribe Members</h2>
                    <p className="mt-4 text-xl text-red-dark">
                      Wir als Gründer (Erik & Darmas) wollen für dich und jedes
                      Mitglied von xTribe da sein. Bekomme kostenlose &
                      exklusive Marketing & Content Strategien, Tribe Leader
                      Kurse, Tipps zur optimierung deiner Plattform und
                      exklusive Updates zu X-Tribe Island und das vor allen
                      anderen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="py-3 lg:p-6 lg:w-1/2">
                <div className="h-full bg-yellow px-10 2xl:px-12 py-12 2xl:py-20">
                  <h6 className="h6 text-red-medium">Für alle Mitglieder</h6>
                  <h2 className="mt-4 h3 text-yellow-dark">
                    Wöchentliche Live Calls
                  </h2>
                  <p className="mt-4 text-xl text-yellow-dark">
                    Es passiert viel in einer Woche, lasst uns das gemeinsam
                    feiern, besprechen, vorstellen und voneinander lernen.
                    Verantwortung, Vertrauen, Vielseitigkeit = X Tribe.
                  </p>
                </div>
              </div>

              <div className="py-3 lg:p-6 lg:w-full">
                <div className="bg-purple-pastel px-10 2xl:px-12 py-12 2xl:py-20">
                  <h6 className="h6 text-green">Und so viel mehr</h6>
                  <h2 className="mt-4 h3 text-purple-dark">xTribe Island</h2>
                  <p className="mt-4 text-xl text-purple-dark">
                    Wir nehmen dich mit auf die wahrscheinlich abenteuerlichste
                    Reise. X Tribe Island ist nicht nur die Mission zur eigenen
                    Insel, sondern eine anhaltende Vision eines Ortes der
                    Vielseitigkeit, Vertrauen, und Verantwortung in Gemeinschaft
                    und Happiness vereint.
                  </p>
                  <div className="h-4" />
                  <Link href="/mission" passHref>
                    <a className=" text-purple hover:text-purple-dark">
                      Unsere Mission &rarr;
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={greenDiv1} className="min-h-screen px-4">
          <div
            className={`transition-opacity duration-700 ease-out ${
              !darkGreen2InView && green1InView
                ? "opacity-100 visible"
                : "opacity-0 invisible"
            }`}
          >
            <div className=" max-w-7xl mx-auto text-center mt-96">
              <h1 className="text-5xl sm:text-6xl  md:text-7xl tracking-tighter leading-none normal-case font-bold mb-4">
                Bist du bereit loszulegen?
              </h1>
            </div>
            <div className="h-16" />
            <div className=" max-w-2xl mx-auto text-center py-96">
              <h1 className="h1">
                <span className="text-green-dark">Don't forget: </span>
                your vibe attracts your tribe
              </h1>
              <div className="h-6" />
              <Link href="/start" passHref>
                <a>
                  <Button size="large" homepageBtn type="success" ghost>
                    Erschaffe deinen Tribe &rarr;
                  </Button>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
      <style jsx global>{`
        :root {
          --color-background: ${colorState.bg};
          --color-background-soft: ${colorState.bgSoft};
          --color-foreground: ${colorState.text};
          --color-foreground-soft: ${colorState.textSoft};
        }
      `}</style>
    </div>
  );
};

export default Index;
