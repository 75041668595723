import { useEffect, useRef, useState } from "react";
import useMediaQuery from "@xtribe/shared/hooks/use-media-query";
interface Props {
  active?: boolean;
  title: string;
  description?: string;
  onClick?: () => void;
}

const SliderItem = ({ active, title, description, onClick }: Props) => {
  const downLg = useMediaQuery("lg", { match: "down" });
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (
      active &&
      ref.current &&
      downLg &&
      ref.current.parentElement?.parentElement
    ) {
      ref.current.parentElement?.parentElement?.scrollTo({
        left: ref.current.parentElement?.offsetLeft - 24,
        behavior: "smooth",
      });
    }
  }, [active]);
  if (!active) {
    return (
      <div
        className="px-6 lg:px-8 py-6 lg:py-2 relative cursor-pointer hover:text-primary h-full"
        role="anchor"
        onClick={() => onClick && onClick()}
      >
        <h5 className="h5">{title}</h5>
        <p className="mt-3 lg:hidden">{description}</p>
      </div>
    );
  }
  return (
    <div className="px-6 lg:px-8 py-6 bg-green-light relative h-full" ref={ref}>
      <h5 className="h5">{title}</h5>
      <p className="mt-3">{description}</p>
    </div>
  );
};

export default SliderItem;
