import Head from "next/head";

interface NextHeadTypes {
  title: string;
  description: string;
  robots?:
    | "noindex, nofollow"
    | "index, follow"
    | "noindex, follow"
    | "index, nofollow";
  siteName?: string;
  url?: string;
  font?: string;
  canonicalUrl?: string;
  image?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImage?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterUrl?: string;
  twitterImage?: string;
}

const NextHead: React.FC<NextHeadTypes> = ({
  title,
  description,
  image,
  ogDescription,
  ogImage,
  ogTitle,
  ogUrl,
  twitterDescription,
  twitterImage,
  twitterTitle,
  twitterUrl,
  font,
  canonicalUrl,
  siteName,
  url,
  robots,
}) => {
  const defaultTitle = "Verdiene Geld mit deinen Online Kursen — xTribe";
  const defaultDescription =
    "Erstelle dein Online Studio in nur 3 Minuten. Biete Livestreaming und On-Demand Kurse an. Verdiene mehr Geld.";
  const defaultImage = "/livestreaming-kurs.jpeg";
  const defaultFont =
    "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
  const defaultCanonicalUrl = "https://xtribe.app";
  const defaultSiteName = "xTribe";
  const defaultUrl = "xtribe.app";
  return (
    <Head>
      <title>{title ?? defaultTitle}</title>
      <meta name="description" content={description ?? defaultDescription} />
      <meta property="og:type" content="website" />
      <meta
        name="og:title"
        property="og:title"
        content={ogTitle ?? title ?? defaultTitle}
      />
      <meta
        name="og:description"
        property="og:description"
        content={ogDescription ?? description ?? defaultDescription}
      />
      <meta property="og:site_name" content={siteName ?? defaultSiteName} />
      <meta property="og:url" content={ogUrl ?? url ?? defaultUrl} />
      <meta property="og:image" content={ogImage ?? image ?? defaultImage} />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={twitterTitle ?? title ?? defaultTitle}
      />
      <meta
        name="twitter:description"
        content={twitterDescription ?? description ?? defaultDescription}
      />
      <meta name="twitter:site" content={twitterUrl ?? url ?? defaultUrl} />
      <meta name="twitter:creator" content={siteName ?? defaultSiteName} />
      <meta
        name="twitter:image"
        content={twitterImage ?? image ?? defaultImage}
      />
      {canonicalUrl && (
        <link rel="canonical" href={canonicalUrl ?? defaultCanonicalUrl} />
      )}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#8dc6bf" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#ffffff" />
      <link href={font ?? defaultFont} rel="stylesheet" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {robots && <meta name="robots" content={robots} />}
    </Head>
  );
};

export default NextHead;
